import "../App.css";

function Footer() {
  return (
    <footer>
      <p>Check me out</p>      
      <a href="https://instagram.com">iolaunchpad</a>
    </footer>
  );
}

export default Footer;