import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./chat.css";

export default function ChatBody({socket}) {
  const [messagesReceived, setMessagesReceived] = useState([]);
  const { loaded, user } = useSelector((store) => store.app);
  const divRef = useRef();

  useEffect(() => {
    socket.on("last_messages", (data) => {
      setMessagesReceived(data.messageList);
    });
    socket.on('message_received', (data) => {
      setMessagesReceived(data.messageList);
    });
    return () => {
      socket.off("last_messages", (data) => {
        setMessagesReceived(data.messageList);
      })
      socket.off("message_received", (data) => {
        setMessagesReceived(data.messageList);
      });
    }
  }, []);

  useEffect(() => {
    window.location.href = "#chat-anchor";
  }, [messagesReceived]);


  return (
    <div className="chat-body">
      {messagesReceived
        ? messagesReceived.map((message, msgIndex) => (
          <div className={message.username == user ? "chat-message blue" : "chat-message green"} key={msgIndex}>
            <p style={{fontWeight: "bold"}}>{message.username}</p>
            <p>{message.messageText}</p>
          </div>
          ))
        : "No messages"}
      <div id="chat-anchor"></div>
    </div>
  )
}