import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../pages/Home";
import Games from "../pages/games/Games";
import GameHome from "../pages/games/GameHome";
import Betbox from "../pages/games/betbox/Betbox";
import "../App.css";

export default function Main() {

  const { pages } = useSelector((store) => store.app);
  
  return (
    <main>
      <div className="Main-container">
      {/*}
          <Route key="home" path="/" element={<Home />} />
          <Route key="games" path="/games" element={<Games />}>
          <Route key="games-home" index element={<GameHome />} />
          <Route key="betbox" path="/games/betbox" element={<Betbox />} />
          </Route>
        */}
        <Routes>
          {pages.map((page, pageInd) => (
            Array.isArray(page["subContent"])
              ? <Route key={page.url} path={page.url} element={page.content}>
                {page["subContent"].map((subPage, SubPageInd) => (
                  Array.isArray(subPage["subContent"])
                    ? <Route key={subPage.url} path={page.url + subPage.url} element={subPage.content}>
                        {subPage['subContent'].map((subPageSec, subPageSecInd) => (
                          <Route key={subPageSec.url} path={page.url + subPage.url + subPageSec.url} element={subPageSec.content} />
                        ))}
                      </Route>
                    : <Route key={subPage.url} path={page.url + subPage.url} element={subPage.content}/>
                ))}
              </Route>
              : <Route key={page.url} path={page.url} element={page.content}/>
          ))}
          <Route key="error" path="/*" element={<div><h3>Error</h3></div>} />
        </Routes>
      </div>
    </main>
  );
}